module.exports = {
	// env: {
	// 	API: "https://dev-isbank-api.iciletisim.app/",
	// 	PORT: "8002",
	// 	COMPANY:"ISBANK",
	// 	BASEAPIURL: "https://dev-isbank-api.iciletisim.app/",
	// },
	env: {
		API: "https://ik.isim.isbank.com.tr/cmsapi",
		PORT: "8002",
		COMPANY:"ISBANK",
		BASEAPIURL: "https://ik.isim.isbank.com.tr/cmsapi/",
	}
}
