import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import Parse from 'parse';
import { EditOutlined, DeleteOutlined, SnippetsOutlined, PlusOutlined, CheckOutlined, CloseOutlined, Loading3QuartersOutlined, SettingOutlined } from "@ant-design/icons";

const Place = (props) => {
    const { Search } = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)

    let [customization] = useGlobal("customization")
    let parseServerURL = customization.parseServerURL
    let parseAppId = customization.parseAppId

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL
    let Place = Parse.Object.extend("Place");

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = { page: page.current, pageSize: page.pageSize }
        } else {
            page = { page: 1, pageSize: 10 };
        }

        let query = new Parse.Query(Place);
        query.limit(1000000)

        if (sorter) {
            sorter.order ? sorter.order = sorter.order : sorter.order = "order"

            if (sorter.field) {
                sorter.order === 'descend' ?
                    query.descending(sorter.field) :
                    query.ascending(sorter.field)
            } else
                query.ascending("order")

        } else
            query.ascending("order")

        await query.find().then(function (results) {
            setLoading(false)
            setTotalCount(results.length);
            results.map((item, key) => {
                let object = item;
                item._id = object.id
                item.name = object.get('name')
                item.order = object.get('order')
                item.active = object.get('active')
                item.description = object.get('description')
                item.coordinates = object.get('coordinates')
                item.workingHours = object.get('workingHours')
                item.diningHall = object.get('diningHall')
                item.service = object.get('service')

                if (item.diningHall)
                    item.diningHall = <CheckOutlined />;
                else
                    item.diningHall = <CloseOutlined />;

                if (item.service)
                    item.service = <CheckOutlined />;
                else
                    item.service = <CloseOutlined />;

                if (item.active)
                    item.active = <CheckOutlined />;
                else
                    item.active = <CloseOutlined />;

                item.key = key;
                return item;
            })

            setData(results);
        }).catch(function (error) {
            setData([]);
        });
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);


    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        let query = new Parse.Query(Place);
        query.equalTo("objectId", item_id);
        let object = await query.first();
        object.destroy().then(() => {
            let newData = data.filter(el => el._id !== item_id);
            setData(newData);
        }, (error) => {
            message.error("Kayıt silinemedi", 2);
        });
    }

    let deleteSelectedRows = async () => {
        selectedRows.map(async item => {
            let query = new Parse.Query(Place);
            query.equalTo("objectId", item._id);
            let object = await query.first();
            object.destroy().then(() => {
                let newData = data.filter(el => el._id !== item._id);
                setData(newData);
            }, (error) => {
                message.error("Bazı ayıtlar silinemedi", 2);
            });
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: 'Bina İsmi',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Aktif',
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['active', 'active']
        },
        {
            title: 'Sıra',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Yemek',
            dataIndex: 'diningHall',
            key: 'diningHall',
            sorter: (a, b) => a.diningHall - b.diningHall,
            sortDirections: ['diningHall', 'diningHall']
        },
        {
            title: 'Servis',
            dataIndex: 'service',
            key: 'service',
            sorter: (a, b) => a.service - b.service,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    {record._id !== "postwall" &&
                        <Link to={"/place/" + record._id + "/plan"}>
                            <Button icon={<SnippetsOutlined />}>Katlar</Button>
                        </Link>
                    }
                    <Link to={"/place/edit/" + record._id}>
                        <Button icon={<EditOutlined />}>Düzenle</Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title="Silmeyi Onaylıyor musunuz?"
                        okText="Onayla" cancelText="Vazgeç">
                        <Button type="danger" icon={<DeleteOutlined />}>
                            Sil
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} - Bina İşlemleri</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/planReservationReport">
                        <Button type="light" icon={<SnippetsOutlined />} style={{ marginRight: "5px" }} size="large">Rezervasyon Raporu (Yeni)</Button>
                    </Link>
                    <Link to="/placeReport">
                        <Button type="light" icon={<SnippetsOutlined />} style={{ marginRight: "5px" }} size="large">Rezervasyon Raporu (Eski)</Button>
                    </Link>
                    <Link to="/settings/reservationAllSettings">
                        <Button type="light" icon={<SettingOutlined />} style={{ marginRight: "5px" }} size="large">Rezervasyon Ayarları</Button>
                    </Link>
                    <Button type="danger" icon={<DeleteOutlined />} size="large" onClick={deleteSelectedRows} style={{ marginRight: "5px" }}>Seçilenleri Sil</Button>
                    <Link to="/place/add">
                        <Button type="light" icon={<PlusOutlined />} style={{ marginRight: "5px" }} size="large">Yeni Ekle</Button>
                    </Link>
                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder="Konu Ara" onChange={onChange} onSearch={(v) => { setSearch(v); get() }} />
                <Table dataSource={data} columns={columns}
                    onChange={handleTableChange} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "large" }}
                    pagination={{
                        total: totalCount
                    }}
                    rowSelection={{ ...rowSelection }} />
            </div>
        </div>
    );
};

export default Place;
