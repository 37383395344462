import React, { useEffect, useState, useGlobal, useMemo } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, InputNumber, Select,Space,Popconfirm,Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput, FileInputSingle } from '../components';
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";
import Parse from 'parse';
import api from '../service/index'
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'

Quill.register('modules/imageResize', ImageResize)

const PlaceDetail = (props) => {
    const quillRef = useRef(null);
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;

    let [customization] = useGlobal("customization")

    let parseServerURL = customization.parseServerURL
    let parseAppId = customization.parseAppId

    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL
    let Place = Parse.Object.extend("Place");
    let Plan = Parse.Object.extend("Plan");
    let PlanCoordinates = Parse.Object.extend("PlanCoordinates");
    let Reservation = Parse.Object.extend("Reservation");



    // kaydet e bastığımda o place id ye ait reservation var mı diye kontrol et
    let checkReservation = async (name) => {

        let ReservationQuery = new Parse.Query(Reservation);
        ReservationQuery.startsWith("reservationTitle",name);
        //2023-12-18T11:54:54.633Z
        ReservationQuery.greaterThan("date",new Date());

        let reservations = await ReservationQuery.find();
     

        let emailUsers = []
        let notEmailUsers = []
        reservations = reservations.filter(reservation => {
            // reservation içinde planCoordinateId var mı diye kontrol et
            let floorId = reservation.attributes.floorId
         
            // tarih şimdi ve sonrası ise
       
                
            let reservationUser = reservation.attributes.userId
            let mailRegex = /\S+@\S+\.\S+/;
            let mailControl = mailRegex.test(reservationUser)
            if(mailControl){
                emailUsers.push(reservationUser)
                return reservation
            } else {
                notEmailUsers.push(reservationUser)
                return reservation
            }
            
        })
    
        // emailUsers aynı olanları sil
        emailUsers = emailUsers.filter((item, index) => {
            return emailUsers.indexOf(item) === index
        })
        // notEmailUsers aynı olanları sil
        notEmailUsers = notEmailUsers.filter((item, index) => {
            return notEmailUsers.indexOf(item) === index
        })
        console.log(emailUsers,"emailUsers+++++++++++++++++++++++++++")
        console.log(notEmailUsers,"notEmailUsers+++++++++++++++++++++++++++")
     
      
       await api.post("/rest/mail/addEmailUser", {emailUsers:emailUsers,notEmailUsers:notEmailUsers, password: data.password,placeName: data.name}).then(({
            data: {
                result,
                result_message
            }
        }) => {
            console.log("result---------",result ? result : result_message)
        });
       
    }

    let newRecord = {
        name: null,
        description: "",
        active: true,
        order: 0,
        workingHours: "",
        diningHall: false,
        service: false,
        timeCategory: "reservationSettings",
        pdf: null,
        isPasswordActive:false,
        password:""
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [baseData, setBaseData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            let query = new Parse.Query(Place);
            query.get(id).then((place) => {
                setBaseData(place.attributes);
                setData(place.attributes);
                setLoading(false)
            }, (error) => {
                message.error("Kayıt bilgilerine ulaşılamadı.", 2);
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null)
            errors.name = 'Zorunlu Alan!'

        if (data.coordinate === null || data.coordinate === undefined)
            errors.coordinate = 'Zorunlu Alan!'

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
    let deleteObject = (obj, prop) => {
        if (Array.isArray(obj[prop]) && ! obj[prop].length) delete obj[prop];
    }
    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><style>*{font-family: '-apple-system','HelveticaNeue'; font-size:14px;}</style>"
            let saveDta = data;
            if(data.fieldOfficeMessage != data.fieldOfficeMessage) {
                saveDta.fieldOfficeMessage = iosFormat + data.fieldOfficeMessage
            } 
            if (id) {
                console.log("PLACE",Place);
                let query = new Parse.Query(Place);
                console.log("password",saveDta.password);
                if(saveDta.password != "" && baseData.password != saveDta.password) {
                    await checkReservation(saveDta.name);
                }
               // 

                query.equalTo("objectId", id);
                await query.first().then((object) => {
                  
                    console.log("data",saveDta);
                    // deleteObject(saveDta,"pdf");
                    console.log("datarevoved",saveDta);
                    object.save(saveDta).then(() => {
                        message.success("Bilgiler güncellendi", 2);
                        history.push('/place')
                    }, (error) => {
                        message.error("Kayıt düzünlenemedi", 2);
                    });

                }, (error) => {
                    message.error("Kayıt bilgilerine ulaşılamadı.", 2);
                });
            } else {
                let Place = Parse.Object.extend("Place")
                let place = new Place()
                console.log(data)
                place.save(data).then((place) => {
                    message.success("Kayıt Başarılı bir şekilde eklendi.", 2);
                    history.push('/place')
                }, (error) => {
                    message.error(error, 2);
                });
            }
        }
    };
    let createPassord = async (passwordCharcaterCount) => {
        if(passwordCharcaterCount){
            var add = 1,
              max = 12 - add;
              console.log(parseInt(passwordCharcaterCount))
              passwordCharcaterCount=parseInt(passwordCharcaterCount)
            if (passwordCharcaterCount > max) {
              return createPassord(max) + createPassord(passwordCharcaterCount - max);
            }
          
            max = Math.pow(10, passwordCharcaterCount + add);
            var min = max / 10; 
            var number = Math.floor(Math.random() * (max - min + 1)) + min;
            setData({ ...data, password: (String(number)).substring(add) })
            console.log((String(number)).substring(add))
            return ("" + number).substring(add);}
    }

    let imageHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {

            const quill = quillRef.current.getEditor();
            const file = input.files[0];
            const formData = new FormData();

            formData.append('files_0', file);

            // Save current cursor state
            const range = quill.getSelection(true);

            // // Insert temporary loading placeholder image
            quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
            // Move cursor to right side of image (easier to continue typing)
            quill.setSelection(range.index + 1);

            let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data' } }).then(({
                data: {
                    result,
                    result_message
                }
            }) => {
                if (result_message.type === 'success') {
                    return result[0].url;
                }
            });
            quill.deleteText(range.index, 1);
            quill.insertEmbed(range.index, 'image', url);
        };
    }

    const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link', 'image', 'color', 'strike', 'blockquote', 'width', 'height']
    const quillmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image', 'video'],
                ['clean'],
                ['code-block']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), [])

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} - Bina İşlemleri</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/place">
                        <Button type="light" icon={<LeftOutlined />} size="large">GERİ</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? "Düzenle" : "Ekle"} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="İsim" required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Aktif" help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                    <Switch checked={data.active ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, active: v })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Sıra">
                                    <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label="Açıklama" help={errors.description} validateStatus={errors.description ? 'error' : 'success'}>
                                    <Input.TextArea name="description" value={data.description} onChange={v => setData({ ...data, description: v.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Çalışma Saatleri" help={errors.workingHours} validateStatus={errors.workingHours ? 'error' : 'success'}>
                                    <Input.TextArea name="workingHours" value={data.workingHours} onChange={e => setData({ ...data, workingHours: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Yemek" help={errors.diningHall} validateStatus={errors.diningHall ? 'error' : 'success'}>
                                    <Switch checked={data.diningHall ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, diningHall: v })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Servis" help={errors.service} validateStatus={errors.service ? 'error' : 'success'}>
                                    <Switch checked={data.service ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, service: v })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>                                <Form.Item label="PDF" required={data.type === 'PDF' ? true : false} help={errors.pdf} validateStatus={errors.pdf ? 'error' : 'success'}>
                                <FileInputSingle name='pdf' fileType='pdf' title='Medyalar' type='file' multi={false} record={data} setRecord={setData} />
                            </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>                                <Form.Item label="Rezervasyon Gün Ayarları" help={errors.timeCategory} validateStatus={errors.timeCategory ? 'error' : 'success'}>
                                <Select defaultValue={(data.timeCategory) ? data.timeCategory : 'Ayar seçiniz'} style={{ width: 300 }} onChange={v => { setData({ ...data, timeCategory: v }) }}>
                                    <Select.Option value="reservationSettings">Genel</Select.Option>
                                    <Select.Option value="reservationSettingsBranch">Şube</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <Form.Item label="Bina giriş şifreli mi?" help={errors.isPasswordActive} validateStatus={errors.isPasswordActive ? 'error' : 'success'}>
                                    <Switch checked={data.isPasswordActive ? true : false} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={v => setData({ ...data, isPasswordActive: v })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {data.isPasswordActive &&
                        <Row direction="row">
                            <Col xs={{ span: 8 }} md={{ span: 8 }}>
                                <Form.Item label="Şifre karakter sayısı" required >
                                    <Input name="passwordCharcaterCount"  onChange={e => setData({ ...data, passwordCharcaterCount: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} md={{ span: 8 }}>
                            <Space size="small">
                            <Popconfirm
                        onConfirm={() => createPassord(data.passwordCharcaterCount)}title="Şifre oluşturmak istediğinizden emin misiniz?"
                        okText="Onayla" cancelText="Vazgeç">
                        <Button type="primary" >
                            Şifre Oluştur
                        </Button>
                    </Popconfirm>
                </Space>

                </Col>
                {data.password &&

                                            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                    <Form.Item label="Giriş Şifreniz">
                                    <Input.TextArea name="password" value={data.password} onChange={v => setData({ ...data, password: v.target.value })} />

                                    </Form.Item>
                                                                </Col>


                }
                </Row>
                }


                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label="Ofis Mesajı" help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <ReactQuill
                                        ref={quillRef}
                                        modules={quillmodules}
                                        formats={formats}
                                        name="fieldOfficeMessage" theme="snow" value={(data.fieldOfficeMessage) ? data.fieldOfficeMessage : ''}
                                        onChange={content => setData({ ...data, fieldOfficeMessage: content })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                                <Form.Item required label="Konum" help={errors.coordinate} validateStatus={errors.coordinate ? 'error' : 'success'}>
                                    <MapInput  record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" required={false} />
                                </Form.Item>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > KAYDET </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default PlaceDetail;
