import React, { useEffect, useGlobal } from "reactn";
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';
import HeaderBar from './layout/HeaderBar';
import {
  Dashboard, RedirectComp,
  Place, PlaceDetail,Plan, PlanDetail,PlanCoordinates, PlanReservation, PlanReservationDetail,PlaceReport,PlanReservationReport,
  NotFoundPage,
  IsbankAttendee, IsbankAttendeeDetail,
} from './pages';
import ReservationSettings from './pages/Settings/ReservationSettings';
import ReservationSettingsBranch from './pages/Settings/ReservationSettingsBranch';
import PageReservationSettings from './pages/Settings/PageReservationSettings';
import PageReservationSettingsBranch from './pages/Settings/PageReservationSettingsBranch';
import PagePublicHolidays from './pages/Settings/PagePublicHolidays';
import PagePublicHolidaysDetail from './pages/Settings/PagePublicHolidaysDetail';
import ReservationAllSettings from './pages/Settings/ReservationAllSettings';
import PageReservationDepartments from './pages/Settings/PageReservationDepartments';
import PageReservationDepartmentsDetail from './pages/Settings/PageReservationDepartmentsDetail';
import ReservationKVKKSettings from './pages/Settings/ReservationKVKKSettings';
import PageReservationKVKKSettings from './pages/Settings/PageReservationKVKKSettings';

const { Header, Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [isSmall, setIsSmall] = useGlobal('isSmall')
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  // let [modules,setModules] = useGlobal("modules");
  // let module = modules[0].refId[0].toUpperCase() + modules[0].refId.substr(1)

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024)
    }
  }, [setIsSmall, setToken])
  useEffect(() => {
    if (isSmall)
      setCollapsed(true)
    else
      setCollapsed(false)
  }, [isSmall, setCollapsed])

  if (!token || !user) {
    props.history.push('/login');
  } else if(typeof user.place != "undefined" && props.history.location.pathname != "/planReservationReport") {
    //console.log("props.history",);
    props.history.push('/planReservationReport');
  }


  return (
    <Switch>
      <Layout style={{ minHeight: '100vh' }}>
        <SideMenu collapsed={collapsed} />
        <Layout className="site-layout" >
          <Header
            className="site-layout-background header"
            style={{ padding: '0 20px' }}
            theme="dark"
          >
            <HeaderBar />
          </Header>

          <Content className="main-wrap">
            <Switch>
              <Route exact path="/" component={RedirectComp} />
              <Route exact path="/adminInfo" component={Dashboard} />

              {/* Settings */}
              
              <Route exact path="/settings/reservationSettings" component={ReservationSettings} />
              <Route exact path="/settings/reservationSettingsBranch" component={ReservationSettingsBranch} />
              <Route exact path="/settings/pageReservationSettings" component={PageReservationSettings} />
              <Route exact path="/settings/pageReservationSettingsBranch" component={PageReservationSettingsBranch} />
            
              <Route exact path="/settings/pagePublicHolidays" component={PagePublicHolidays} />
              <Route exact path="/settings/pagePublicHolidays/:id/edit/:subId" component={PagePublicHolidaysDetail} />
              <Route exact path="/settings/pagePublicHolidays/:id/add" component={PagePublicHolidaysDetail} />

              <Route exact path="/settings/reservationAllSettings" component={ReservationAllSettings} />
              <Route exact path="/settings/pageReservationDepartments" component={PageReservationDepartments} />
              <Route exact path="/settings/pageReservationDepartments/edit/:id" component={PageReservationDepartmentsDetail} />
              <Route exact path="/settings/pageReservationDepartments/add" component={PageReservationDepartmentsDetail} />

              <Route exact path="/settings/reservationKVKKSettings" component={ReservationKVKKSettings} />
              <Route exact path="/settings/pageReservationKVKKSettings" component={PageReservationKVKKSettings} />
              {/* Settings */}
          
              <Route exact path="/attendees" component={IsbankAttendee} />
              <Route exact path="/attendees/edit/:id" component={IsbankAttendeeDetail} />

              <Route exact path="/place" component={Place} />
              <Route exact path="/place/edit/:id" component={PlaceDetail} />
              <Route exact path="/place/add" component={PlaceDetail} />

              <Route exact path="/place/:placeId/plan" component={Plan} />
              <Route exact path="/place/:placeId/plan/edit/:id" component={PlanDetail} />
              <Route exact path="/place/:placeId/plan/add" component={PlanDetail} />

              <Route exact path="/place/:placeId/plan/edit/:id/coordinates" component={PlanCoordinates} />
              <Route exact path="/place/:placeId/plan/edit/:id/reservation" component={PlanReservation} />
              <Route exact path="/planReservation/:id" component={PlanReservationDetail} />

              <Route exact path="/placeReport" component={PlaceReport} />
              <Route exact path="/planReservationReport" component={PlanReservationReport} />

              <Route path='*' component={NotFoundPage} />

            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Switch>
  );
};

export default Admin;
