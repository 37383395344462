import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input, Card, Tabs, Popconfirm } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import { EditOutlined, CheckOutlined, CloseOutlined, DownloadOutlined, Loading3QuartersOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
const { TabPane } = Tabs
const Attendee = (props) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })
  let [data, setData] = useState([]);
  let [constData, setConstData] = useState([]);
  let [totalCount, setTotalCount] = useState(0)
  let [, setSearch] = useState(false)
  let [loading, setLoading] = useState(false);
  let [searchTerm, setSearchTerm] = useState("");
  let [activeTab, setActiveTab] = useState("1");
  let [languagesCms] = useGlobal("languagesCms");
  let [modules] = useGlobal("modules");


  let columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => (b.email || "").localeCompare(a.email || "")
    }, {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => (b.phone || "").localeCompare(a.phone || "")
    }, {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (b.name || "").localeCompare(a.name || "")
    },
    {
      title: 'Departman',
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => (b.position || "").localeCompare(a.position || "")
    },
    {
      title: 'Durumu',
      width: "50px",
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      sorter: (a, b) => (a.active ? 1 : 0) - (b.active ? 1 : 0),
      render: (active) => <div className="w-100 centered">{active ? <div className="true-circle"></div> : <div className="false-circle"></div>}</div>
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Kayıt Tarihi',
      width: "200px",
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
      render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm")
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={"/attendees/edit/" + record._id}>
            <Button icon={<EditOutlined />}>{languagesCms && languagesCms.EDIT}</Button>
          </Link>
          {activeTab !== "3" && <Popconfirm
            onConfirm={() => deletedUser(record._id)} title="Silmeyi Onaylıyor musunuz?"
            okText="Onayla" cancelText="Vazgeç">
            <Button type="danger" icon={<DeleteOutlined />}>
            </Button>
          </Popconfirm>}
        </Space>
      ),
    },
  ];

  let query = []
  query["allow_donation"] = false;
  query["blood_donation"] = false;
  query["platelet_donation"] = false;


  let get = async (page, filters, sorter) => {
    setLoading(true)
    if (page) {
      page = { page: page.current, pageSize: page.pageSize }
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'created_at';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
      }
    }

    let _params = { sort: shortString, ...page, query: { selfRegistered: true, active: activeTab === "1" ? true : false, deleted: activeTab === "3" ? true : false, } };
    if (searchTerm.length > 2)
      _params.search = searchTerm
    let restData = await api.get(`/rest/attendees?${qs.stringify(_params)}`,).then(({ data }) => {
      if (data.result) {
        setLoading(false)
        setTotalCount(data.result.total);
        return data.result.rows.map((item, key) => {

          if (!item.hasOwnProperty('groups')) {
            item.groups = []
          }
          if (!item.deleted)
            item.deleted = false;
          else
            item.deleted = true;

          item.key = key;
          return item;
        })
      }
    });

    setConstData(restData)
    setData(restData);
  }

  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
    }
  }, [])

  useEffect(() => {
    if (searchTerm.length === 0)
      get({ current: 1, pageSize: 10 }, {}, {});
    setSearchTerm("")
  }, [activeTab])

  useEffect(() => {
    if (searchTerm.length > 2 || searchTerm.length === 0) {
      get({ pagination, current: 1, pageSize: 10 })
      setPagination({ current: 1, pageSize: 10 })
    }
  }, [searchTerm])

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
    setPagination(page, filters, sorter)
  }

  const deletedUser = async (id) => {
    await api.delete(`/rest/attendees/${id}`)
    get({ current: 1, pageSize: 10 }, {}, {});
  }

  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];
    let query = []

    query["deleted"] = false;

    let excelData = await api.get(`/rest/attendees?${qs.stringify({ page: 1, pageSize: 20000, query })}`, {}).then(({ data }) => {
      return data.result.rows;
    });

    if (excelData) {
      for (let i in excelData) {

        let obj = {
          'id': excelData[i]._id,
          'role': excelData[i].role,
          'name': excelData[i].name,
          'lastname': excelData[i].lastname,
          'phone': excelData[i].phone,
          'email': excelData[i].email,
          'city': excelData[i].location,
          'birthday': excelData[i].birthday,
          'position': excelData[i].position,
          'deleted': excelData[i].deleted ? 'Silindi' : 'Aktif',

        }

        //Ek alanlar 
        obj.company = excelData[i].company;

        obj.position = excelData[i].position;

        obj.department = excelData[i].department;



        dataTable.push(obj);
      }
    }

    let sheetAreas = ['id', 'role', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'deleted'];

    //Ek alanlar 
    sheetAreas.push("company");

    sheetAreas.push("position");

    sheetAreas.push("department");


    option.fileName = 'UserList'
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['id', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'position', 'deleted'],
        sheetHeader: ['id', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'position', 'deleted'],

      }
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  return (
    <div>
      <div className="list-head">
        <div className="list-buttons">
          {/* <Button type="primary" icon={<FileMarkdownOutlined />} size="large" style={{ marginRight: "5px" }} onClick={() => getReport()}>Giriş Logları İndir</Button> */}

          <Button size="large" onClick={downloadExcel} style={{ marginRight: "5px" }} icon={<DownloadOutlined />}>Export Excel</Button>


        </div>
      </div>

      <div className="table-wrap">
        <Card bodyStyle={{ padding: "5px 0px" }}>
          <Tabs defaultActiveKey={activeTab} type="card" onChange={(v) => { setPagination({ current: 1 }); setActiveTab(v); }}>
            <TabPane tab="Aktif Kullanıcılar" key="1">
              <Input value={searchTerm} size="large" placeholder="Ara... (Min 3 Karakter)" onChange={(e) => setSearchTerm(e.target.value)} />
              <Table columns={columns}
                dataSource={data}
                loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "medium" }}
                onChange={handleTableChange}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: totalCount
                }}
              />
            </TabPane>
            <TabPane tab="Pasif Kullanıcılar" key="2">
              <Input value={searchTerm} size="large" placeholder="Ara... (Min 3 Karakter)" onChange={(e) => setSearchTerm(e.target.value)} />
              <Table columns={columns} dataSource={data} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "medium" }}
                onChange={handleTableChange}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: totalCount
                }}
              />
            </TabPane>

            <TabPane tab="Silinmiş Kullanıcılar" key="3">
              <Input value={searchTerm} size="large" placeholder="Ara... (Min 3 Karakter)" onChange={(e) => setSearchTerm(e.target.value)} />
              <Table columns={columns} dataSource={data} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "medium" }}
                onChange={handleTableChange}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: totalCount
                }}
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>

    </div >
  );
};



export default Attendee;
