import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { env } from '../app.config'
import { useHistory } from "react-router";

const Login = (props) => {

  let [token, setToken] = useGlobal("token");
  let [modules, setModules] = useGlobal("modules");
  let [user, setUser] = useGlobal("user");
  let [settings, setSettings] = useGlobal("settings");
  let [customization, setCustomization] = useGlobal("customization");
  let [langs, setLangs] = useGlobal("lang");
  let [pdfIcon, setPdfIcon] = useGlobal("pdf");
  let [logo, setLogo] = useGlobal("logo");
  let [login_setting, setLogin_setting] = useGlobal("login_setting");
  let history = useHistory()

  const auth = async ({ username, password }) => {
    let params = {
      "email": username,
      "password": password,
      "loginType": "emailPass"
    }

    let query = {
      "lang": "TR",
      "adminShow": "true"
    }

    delete api.headers['Authorization'];
    await api.post('/api/login', params).then(async ({ data }) => {

      setToken(data.result.token);
      setUser(data.result);
      localStorage.setItem("user", JSON.stringify(data.result));
      //Cookies.set("user", data.result)

      api.setHeader('Authorization', "Bearer " + data.result.token);

      Cookies.set("token", data.result.token)

      await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
        setSettings(data.result.rows);
        if (data.result.rows) {
          data.result.rows.forEach(element => {
            if (element._id === "customization") {
              setCustomization(element)
              localStorage.setItem("customization", JSON.stringify(element));
              setLogo(element.cmsLogo)
              localStorage.setItem("logo", JSON.stringify(element.cmsLogo));
            }
            if (element._id === "login_setting") {
              setLogin_setting(element.companyName)
              localStorage.setItem("login_setting", JSON.stringify(element.companyName));
            }
            if (element._id === "supportedLangs") {
              setLangs(element.items)
              localStorage.setItem("langs", JSON.stringify(element.items[0]));
            }
            if (element._id === "surveyWithMediaIcons") {
              setPdfIcon(element.pdf)
              localStorage.setItem("pdfIcon", JSON.stringify(element.pdf));
            }
          });
        }
        localStorage.setItem("settings", JSON.stringify(data.result.rows));
      })

      await api.get(`/rest/modules?${qs.stringify(query)}`).then(({ data }) => {

        setModules(data.result.rows);
        localStorage.setItem("modules", JSON.stringify(data.result.rows));
        props.history.push(data.result.rows[0]._id);
      })



    }).catch((e) => {
      alert('Password or Username is incorrect');
    });

  };

  const onFinish = (values) => {
    auth(values);
  };
  console.log("env.COMPANY",env.COMPANY);
  if (env.COMPANY == "ISBANK") {
    return (
      <div className="login-wrap h-100">
        <div className="form" style={{ alignItems: "center" }}>
          <a href={env.REDIRECTURL} className="ant-btn ant-btn-light ant-btn-lg">Tekrar giriş yapmak için tıklayınız.</a>
        </div>
      </div>
    )
    } else {
      return (
        <div className="login-wrap">
          <div className="form">
            < Form
              name="normal_login"
              className="login-form"
              size="large"
              initialValues={{
                remember: true,
              }} 
              onFinish={onFinish}
            >
              <div className="logo"></div>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="E-Posta"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Sifre"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="light"
                  htmlType="submit"
                  className="login-form-button"
                  id="1"
                >
                  Giriş yap
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div >
      );
    }
};

export default Login;
